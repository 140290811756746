@tailwind base;
@tailwind components;
@tailwind utilities;
  
body {
    @apply bg-white text-dark-grey;
}

h1 {
    @apply text-lg font-bold;
}

h2 {
    @apply text-base font-bold;
}

h3 {
    @apply text-base font-semibold;
}

h4 {
    @apply text-sm font-semibold md:text-base;
}

input, select, textarea {
    @apply transition-all border rounded border-border focus:outline-none ;
}

input::placeholder,
textarea::placeholder {
    @apply text-gray-300 dark:text-zinc-500;
}

.card {
    @apply bg-white border rounded drop-shadow;
}

.responsive-padding {
    @apply px-6 py-4 md:px-10;
}

.responsive-margin {
    @apply mx-6 my-4 md:my-8 md:mx-10 lg:my-12 lg:mx-16;
}

.responsive-navbar-padding {
    @apply px-6 py-4 md:px-10;
}

/* This is used on the subscription cards */
.gradient-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2));
    pointer-events: none;
    z-index: 1;
  }

.lighten-on-hover:hover {
    filter: brightness(1.1);
}

.dropdown-item:focus-visible {
    @apply outline-none ring-1 ring-primary;
  }

:focus-visible {
    @apply outline-none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
}

/* Could add a, button, input, textarea, select */
div {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* Custom media queries */
@media (min-width: 768px) {
    .md\:card {
        @apply bg-white border rounded drop-shadow;
    }
    .responsive-navbar-padding {
        @apply p-0; 
    }
    .grid-custom {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
}